import {isAndroid, isIOS, isMobile} from 'react-device-detect'
import DOMPurify from 'dompurify'
import {parse} from 'query-string'
import momentLang from 'moment'
import {savePrivacy} from '../../redux/actions/PrivaciesActions'
import {APP_CONSTANT, FEATURE_UI_NAME} from './Constants'
import i18n from '../../i18n'
import _, {sortBy, cloneDeep, merge} from 'lodash'
import {unlinkConsent} from '../../redux/actions/ConsentActions'

function getConfigurationForLocalDevelopment(urlParams) {
  //For development
  //TODO: update country based on testing below
  APP_CONSTANT.CONFIG.ENV = 'qa'
  APP_CONSTANT.CONFIG.COUNTRY_CODE = urlParams.get('country_code') || 'USA'
  // console.log(APP_CONSTANT.CONFIG.COUNTRY_CODE)
  APP_CONSTANT.CONFIG.BRAND_CODE = 'F'
  APP_CONSTANT.CONFIG.LANGUAGE_CODE = urlParams.get('language_code')
  APP_CONSTANT.CONFIG.ONSET = 'owner'
  APP_CONSTANT.CONFIG.EU_PRIVACY_POLICY_LINK =
    'https://www.fordpass.com/content/ford_com/fp_app/en_gb/privacy.html'
  APP_CONSTANT.CONFIG.MOMENT_LANGUAGE_CODE = {
    code: 'en-GB',
    consentFormat: 'MMMM DD YYYY',
  }
  APP_CONSTANT.CONFIG.ONSET = 'fordpass'
  //USA
  APP_CONSTANT.CONFIG.APPLICATION_ID = 'CF6B55B2-FA92-40EE-8EE6-1D2EE61831A9'
  //EU
  // APP_CONSTANT.CONFIG.APPLICATION_ID = '4749EAFC-B5F5-4936-8156-55693824B063'
  APP_CONSTANT.CONFIG.APPLICATION_ID_FOR_SAVE = 'B6B7D9F0-73B0-11E7-86AA-000D3A196736'
  APP_CONSTANT.CONFIG.CARB_APP_ID = '58C1BBF5-896E-4300-9F51-A1F98A27F467'
  APP_CONSTANT.CONFIG.ALEXA_APP_ID = 'c3a54bb4-bffa-42bb-b35a-af306e1881ee'
  APP_CONSTANT.CONFIG.IS_OWNER = urlParams.get('onset') === 'owner'
  APP_CONSTANT.CONFIG.SHOW_HEADER_FOOTER_EU = true
  APP_CONSTANT.CONFIG.REGION = 'NA'
}

export const utils = {
  getParams: (param) => {
    const urlParams = parse(window.location.hash)
    if (!utils.errors.includes(urlParams[param])) {
      return urlParams[param]
    }
    return ''
  },

  errors: [null, undefined, {}, '', [], 'null'],

  paramsExists: (param) => () => !utils.errors.includes(utils.getParams(param)),

  getFavicon: () => {
    const favicon = document.createElement('link')
    favicon.rel = 'shortcut icon'
    if (APP_CONSTANT.CONFIG.BRAND_CODE === 'F') {
      favicon.href = '/assets/images/ford.ico'
    } else {
      favicon.href = '/assets/images/lincoln.svg'
    }
    document.head.appendChild(favicon)
  },

  tokenExists: () => utils.paramsExists('access_token')(),

  initialSetUpValues: () => {
    APP_CONSTANT.CONFIG.ENV = window.app_properties.env
    APP_CONSTANT.CONFIG.LOADED_URL = window.location.origin
    APP_CONSTANT.CONFIG.APPLICATION_ID = window.app_properties.consentAppId
    APP_CONSTANT.CONFIG.CLIENT_ID = window.app_properties.fma_data_b2c_cid
    APP_CONSTANT.CONFIG.REGION = window.app_properties.region
    const urlParams = new URLSearchParams(window.location.search)

    if (APP_CONSTANT.CONFIG.LOADED_URL.search('lincoln') === -1) {
      APP_CONSTANT.CONFIG.BRAND_CODE = 'F'
    } else {
      APP_CONSTANT.CONFIG.BRAND_CODE = 'L'
      APP_CONSTANT.CONFIG.APPLICATION_ID = window.app_properties.lincolnConsentAppId
    }
    APP_CONSTANT.CONFIG.CARB_APP_ID = window.app_properties.carbAppId
    APP_CONSTANT.CONFIG.ALEXA_APP_ID = window.app_properties.alexaAppId
    APP_CONSTANT.CONFIG.LANGUAGE_CODE = DOMPurify.sanitize(window.app_properties.language_code)
    APP_CONSTANT.CONFIG.COUNTRY_CODE = DOMPurify.sanitize(window.app_properties.Country_code)
    APP_CONSTANT.CONFIG.APIGEE_URL = window.app_properties.apiGeeUrl
    APP_CONSTANT.CONFIG.TRACE_ID = window.app_properties.traceId
    APP_CONSTANT.CONFIG.SPAN_ID = window.app_properties.spanId
    APP_CONSTANT.CONFIG.EU_PRIVACY_POLICY_LINK = window.app_properties.euPrivacyUrlLink
    APP_CONSTANT.CONFIG.ONSET =
      window.app_properties.onset === null ? 'fordpass' : window.app_properties.onset
    APP_CONSTANT.CONFIG.IS_OWNER = APP_CONSTANT.CONFIG.ONSET === 'owner'
    APP_CONSTANT.CONFIG.SHOW_HEADER_FOOTER_EU = window.app_properties.show_owner_header_footer_eu
    APP_CONSTANT.CONFIG.MOMENT_LANGUAGE_CODE = {
      code: DOMPurify.sanitize(window.app_properties.language_code),
      consentFormat: 'MMMM DD YYYY',
    }

    if (APP_CONSTANT.CONFIG.ONSET === 'fordpasspro') {
      APP_CONSTANT.CONFIG.APPLICATION_NAME = 'FordPass Pro'
      APP_CONSTANT.CONFIG.APPLICATION_ID_FOR_SAVE = window.app_properties.fordpassProApplicationId
    } else {
      APP_CONSTANT.CONFIG.APPLICATION_NAME = 'FordPass'
      APP_CONSTANT.CONFIG.APPLICATION_ID_FOR_SAVE = window.app_properties.fordpassApplicationId
    }

    if (process.env.NODE_ENV !== 'production') {
      getConfigurationForLocalDevelopment(urlParams)
    }

    if (isMobile) {
      if (isAndroid) {
        APP_CONSTANT.CONFIG.DEVICE_TYPE = 'Android'
      }
      if (isIOS) {
        APP_CONSTANT.CONFIG.DEVICE_TYPE = 'IOS'
      }
    } else {
      APP_CONSTANT.CONFIG.DEVICE_TYPE = 'Web'
    }
    //Language Translation to be loaded
    i18n.changeLanguage(APP_CONSTANT.CONFIG.LANGUAGE_CODE)
  },

  addOneTrustCookieScript: () => {
    const cookieScript = document.createElement('script')
    cookieScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    if (APP_CONSTANT.CONFIG.BRAND_CODE === 'F') {
      cookieScript.setAttribute('data-domain-script', window.app_properties.oneTrust_data_domain_script_ford)
    } else {
      cookieScript.setAttribute(
        'data-domain-script',
        window.app_properties.oneTrust_data_domain_script_lincoln,
      )
    }
    document.body.appendChild(cookieScript)

    const cookieScriptFn = document.createElement('script')

    document.body.appendChild(cookieScriptFn)

    // eslint-disable-next-line func-name-matching
    cookieScriptFn.onload = function OptanonWrapper() {}
  },

  addFmaScript: () => {
    const script = document.createElement('script')

    function setLincolnOrFordCidForLocalNA() {
      if (APP_CONSTANT.CONFIG.BRAND_CODE === 'L') {
        script.setAttribute('data-client-id', '8c9b3c1d-f13b-4fc5-903c-9b3b8dd18151')
      } else {
        script.setAttribute('data-client-id', '746c1a85-bbd5-437b-8142-25bc64cbbbd9')
      }
    }

    //This block encompasses all cloud environments, not just prod
    if (process.env.NODE_ENV === 'production') {
      script.src = window.app_properties.fma_src
      script.setAttribute('data-fma-script', '')
      script.setAttribute('data-client-id', APP_CONSTANT.CONFIG.CLIENT_ID)
      script.setAttribute('data-app-id', APP_CONSTANT.CONFIG.APPLICATION_ID)
      script.setAttribute('data-property-key', 'default')
      script.setAttribute(
        'data-state',
        `${APP_CONSTANT.CONFIG.COUNTRY_CODE},${APP_CONSTANT.CONFIG.LANGUAGE_CODE},${APP_CONSTANT.CONFIG.ONSET}`,
      )
    } else {
      script.src = 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js'
      script.setAttribute('data-fma-script', '')
      script.setAttribute('data-property-key', 'default')
      if (['USA', 'CAN'].includes(APP_CONSTANT.CONFIG.COUNTRY_CODE)) {
        setLincolnOrFordCidForLocalNA()
        script.setAttribute('data-app-id', 'CF6B55B2-FA92-40EE-8EE6-1D2EE61831A9')
        script.setAttribute('data-state', APP_CONSTANT.CONFIG.COUNTRY_CODE)
      } else {
        script.setAttribute('data-app-id', '4749EAFC-B5F5-4936-8156-55693824B063')
        script.setAttribute('data-client-id', 'd5c4196d-054f-49f2-8903-79245d3bae2b')
        script.setAttribute('data-state', APP_CONSTANT.CONFIG.COUNTRY_CODE)
      }
    }
    document.body.appendChild(script)
  },
  getBackgroundColor: () => {
    const x = document.getElementsByTagName('body')[0]
    if (APP_CONSTANT.CONFIG.BRAND_CODE === 'F') x.className = 'background-color-F'
    else x.className = 'background-color-L'
  },

  setBrandDataSet: () => {
    if (APP_CONSTANT.CONFIG.BRAND_CODE === 'L') document.documentElement.dataset.brand = 'lincoln'
  },

  formatDate: (timeStamp, triggerUserId, authorizedUsers) => {
    momentLang.locale(APP_CONSTANT.CONFIG.MOMENT_LANGUAGE_CODE.code)
    return momentLang(timeStamp).format(utils.getTimeFormatWithTriggerUserId(triggerUserId, authorizedUsers))
  },

  formatDateForGivenFormat: (timeStamp, format, isUtc = true) => {
    momentLang.locale(APP_CONSTANT.CONFIG.MOMENT_LANGUAGE_CODE.code)

    return isUtc ? momentLang(timeStamp).utc().format(format) : momentLang(timeStamp).format(format)
  },

  getTimeFormatWithTriggerUserId: (triggerUserId, authorizedUsers) => {
    if (
      !utils.errors.includes(triggerUserId) &&
      !utils.errors.includes(authorizedUsers) &&
      authorizedUsers.length > 1
    ) {
      const triggerUserDetails = authorizedUsers.find(
        (user) => user.guid.toLowerCase() === triggerUserId.toLowerCase(),
      )
      if (utils.errors.includes(triggerUserDetails)) {
        return `[${i18n.t('lbl_last_updated_at')}] h:mm A [${i18n.t('lbl_on')}] MMMM Do YYYY.`
      } else {
        const triggerUserName = triggerUserDetails.displayName
        return `[${i18n.t('lbl_last_updated_by')}] [${triggerUserName}] [${i18n.t(
          'lbl_at',
        )}] h:mm A [${i18n.t('lbl_on')}] MMMM Do YYYY.`
      }
    } else {
      return `[${i18n.t('lbl_last_updated_at')}] h:mm A [${i18n.t('lbl_on')}] MMMM Do YYYY.`
    }
  },

  getSplitParagraphs: (props) => {
    let paragraphs = []
    if (props.subtitle === FEATURE_UI_NAME.txu) {
      paragraphs = props.content.split('#')
    } else {
      paragraphs = props.content.split(':')
    }
    return paragraphs
  },
  dispatchSavePrivacy: (
    dispatch,
    appDetails,
    vin,
    statusCode,
    preferredDealerId,
    applicationIdForSave,
    groupName,
  ) => {
    function createRequestByApp() {
      switch (appDetails.uiName) {
        case 'Mobile App Cookies':
          return {
            guid: APP_CONSTANT.CONFIG.USER_GUID,
            countryCode: APP_CONSTANT.CONFIG.COUNTRY_CODE,
            privacyPreferences: appDetails.groupedConsentNames[`${groupName}`].map((consentName) => {
              return {
                consentName,
                statusCode,
                devices: [
                  {
                    deviceType: 'Application',
                    deviceValue: APP_CONSTANT.CONFIG.APPLICATION_NAME,
                  },
                ],
              }
            }),
          }

        case FEATURE_UI_NAME.preferredDealer:
          return {
            guid: APP_CONSTANT.CONFIG.USER_GUID,
            countryCode: APP_CONSTANT.CONFIG.COUNTRY_CODE,
            privacyPreferences: appDetails.consentNames.map((consentName) => {
              return {
                consentName,
                devices: [
                  {
                    deviceType: 'VIN',
                    deviceValue: vin,
                  },
                ],
                statusCode,
                preferredDealerId,
              }
            }),
          }

        default:
          return {
            guid: APP_CONSTANT.CONFIG.USER_GUID,
            countryCode: APP_CONSTANT.CONFIG.COUNTRY_CODE,
            privacyPreferences: appDetails.consentNames.map((consentName) => {
              return {
                consentName,
                devices: [
                  {
                    deviceType: 'VIN',
                    deviceValue: vin,
                  },
                ],
                statusCode,
              }
            }),
          }
      }
    }

    dispatch(
      savePrivacy({
        data: createRequestByApp(),
        appId:
          appDetails.uiName === FEATURE_UI_NAME.californiaAirResourcesBoard
            ? APP_CONSTANT.CONFIG.CARB_APP_ID
            : applicationIdForSave,
        uiName: appDetails.uiName,
      }),
    )
  },

  processAppsByVin: (app) => {
    const result = {}
    app.consents.forEach(
      (consentObj) =>
        (result[consentObj.vin] = {
          uiName: app.uiName,
          applicationId: app.applicationId,
          uiImage: app.uiImage,
          sourceId: app.sourceId,
          brand: app.brand,
          isLaunched: app.isLaunched,
          type: app.type,
          learnMoreCategories: app.learnMoreCategories,
          consentTimeStamp: consentObj.consentTimestamp,
          consentStatus: consentObj.consentStatus,
          llId: consentObj.llId,
          vin: consentObj.vin,
          appCountryCode: consentObj.appCountryCode,
          consentContext: consentObj.consentContext,
        }),
    )
    return result
  },

  formatVehicleDataByProvider: (authorrizedVinData, manageData) => {
    const manageDataVins = manageData.map((vehicleDataArray) => vehicleDataArray.key)
    const authorizedVehicleData = authorrizedVinData.filter((vinData) =>
      _.includes(manageDataVins, vinData.vin),
    )
    const formattedVehicleData = authorizedVehicleData.map((vehicleData) => {
      const vehicleName = vehicleData.nickName
        ? vehicleData.nickName
        : `${vehicleData.modelYear} ${vehicleData.vehicleLine} ${vehicleData.color}`
      return {
        vin: vehicleData.vin,
        vehicleName,
      }
    })
    return formattedVehicleData
  },

  getOrderedVehicleData: (vehicleDataList) => {
    const isLeadingCharacterDigit = new RegExp(/^\d/)
    const letterLeadingVehicleNamesData = sortBy(
      vehicleDataList.filter((vehicleData) => !isLeadingCharacterDigit.test(vehicleData.vehicleName)),
    )
    const digitLeadingVehicleNamesData = sortBy(
      vehicleDataList.filter((vehicleData) => isLeadingCharacterDigit.test(vehicleData.vehicleName)),
    )
    return letterLeadingVehicleNamesData.concat(digitLeadingVehicleNamesData)
  },

  dispatchUnlinkConsent: (dispatch, selectedApplication) => {
    dispatch(unlinkConsent(selectedApplication))
  },

  getTabIndex: (isDialogOpen) => {
    return isDialogOpen ? -1 : 0
  },

  filterObject: (object, allowedKeys) => {
    return Object.keys(object)
      .filter((key) => allowedKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = object[key]
        return obj
      }, {})
  },

  deepMergeObjects: (...objects) => {
    const deepCopyObjects = objects.map((object) => cloneDeep(object))
    return deepCopyObjects.reduce((merged, current) => merge(merged, current))
  },

  toCamelCase: (str) => {
    const spaceRegEx = /\s+/g
    const firstCharRegEx = /(?:^\w|[A-Z]|\b\w)/g
    return str
      .replace(firstCharRegEx, (word, i) => (i == 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(spaceRegEx, '')
  },
}
