import React from 'react'
import {
  AMPLITUDE_LINK_NAME,
  AMPLITUDE_SCREEN_NAME,
} from '../../components/helperComponents/Analytics/AnalyticsConstants'
import {analytics} from '../../components/helperComponents/Analytics/Analytics'
import {useTranslation} from 'react-i18next'
import {useBrandedTranslation} from '../helperComponents/contentUtils'

export default function BottomLinks(props) {
  const {t} = useTranslation()

  function handlePrivacyNoticesClick() {
    analytics.fireLinkTappedEvent(
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_LINK_NAME.PRIVACY_NOTICES_LINK,
    )
  }

  function handleAppPrivacyNoticesClick() {
    analytics.fireLinkTappedEvent(
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_LINK_NAME.FORDPASS_PRIVACY_NOTICES_LINK,
    )
  }

  function handleYourPrivacyChoicesClick() {
    analytics.fireLinkTappedEvent(
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_LINK_NAME.YOUR_PRIVACY_CHOICE_LINK,
    )
  }

  function renderLink(url, onClickFn, label, iconModifier) {
    const hasIcon = !!iconModifier

    return (
      <div
        className={`bottom-links__link-wrapper ${hasIcon && 'flex flex-center flex-row flex-item-center'}`}
      >
        <a
          data-testid="bottom-link"
          className={`fmc-text-button bottom-links__link ${
            iconModifier && 'flex flex-center flex-row flex-item-center'
          }`}
          target="_blank"
          rel="noreferrer"
          href={url}
          onClick={onClickFn}
        >
          {label}
        </a>
        {hasIcon && <div className={`bottom-links__${iconModifier}`} data-testid="bottom-link-icon" />}
      </div>
    )
  }

  switch (props.countryCode) {
    case 'USA': {
      return (
        <div className="bottom-links" data-testid="bottom-links">
          {renderLink(
            useBrandedTranslation('lbl_privacy_notices_url_USA'),
            handlePrivacyNoticesClick,
            t('lbl_privacy_notices'),
          )}
          {renderLink(
            useBrandedTranslation('lbl_app_privacy_notice_url_USA'),
            handleAppPrivacyNoticesClick,
            useBrandedTranslation('lbl_app_privacy_notice'),
          )}
          {renderLink(
            useBrandedTranslation('lbl_your_privacy_choices_url'),
            handleYourPrivacyChoicesClick,
            t('lbl_your_privacy_choices'),
            'privacy-choices-icon',
          )}
        </div>
      )
    }
    case 'CAN': {
      return (
        <div className="bottom-links">
          {renderLink(
            useBrandedTranslation('lbl_privacy_notices_url_CAN'),
            handlePrivacyNoticesClick,
            t('lbl_privacy_notices'),
          )}
          {renderLink(
            useBrandedTranslation('lbl_app_privacy_notice_url_CAN'),
            handleAppPrivacyNoticesClick,
            useBrandedTranslation('lbl_app_privacy_notice'),
          )}
        </div>
      )
    }
    case 'ARG': {
      return (
        <div className="bottom-links">
          {renderLink(
            t('lbl_fordpass_terms_and_privacy_policy_url_ARG'),
            handlePrivacyNoticesClick,
            t('lbl_fordpass_terms_and_privacy_policy'),
          )}
        </div>
      )
    }
    case 'AUS': {
      return (
        <div className="bottom-links">
          {renderLink(
            t('lbl_fordpass_terms_and_privacy_policy_url_AUS'),
            handlePrivacyNoticesClick,
            t('lbl_fordpass_terms_and_privacy_policy'),
          )}
        </div>
      )
    }
    case 'NZL': {
      return (
        <div className="bottom-links">
          {renderLink(
            t('lbl_fordpass_terms_and_privacy_policy_url_NZL'),
            handlePrivacyNoticesClick,
            t('lbl_fordpass_terms_and_privacy_policy'),
          )}
        </div>
      )
    }
    case 'PHL': {
      return (
        <div className="bottom-links">
          {renderLink(
            t('lbl_fordpass_terms_and_privacy_policy_url_PHL'),
            handlePrivacyNoticesClick,
            t('lbl_fordpass_terms_and_privacy_policy'),
          )}
          {renderLink(
            t('lbl_privacy_policy_and_disclaimer_PHL'),
            handlePrivacyNoticesClick,
            t('lbl_privacy_policy_and_disclaimer'),
          )}
        </div>
      )
    }
    case 'THA': {
      return (
        <div className="bottom-links">
          {renderLink(
            t('lbl_fordpass_terms_and_privacy_policy_url_THA'),
            handlePrivacyNoticesClick,
            t('lbl_fordpass_terms_and_privacy_policy'),
          )}
          {renderLink(
            t('lbl_privacy_notice_for_customer_THA'),
            handlePrivacyNoticesClick,
            t('lbl_privacy_notice_for_customer'),
          )}
        </div>
      )
    }
    case 'MEX': {
      return (
        <div className="bottom-links">
          {renderLink(
            useBrandedTranslation('lbl_privacy_notices_url_MEX'),
            handlePrivacyNoticesClick,
            t('lbl_privacy_notices'),
          )}
          {renderLink(
            useBrandedTranslation('lbl_app_privacy_notice_url_MEX'),
            handleAppPrivacyNoticesClick,
            useBrandedTranslation('lbl_app_privacy_notice'),
          )}
        </div>
      )
    }
    default: {
      return (
        <div className="bottom-links">
          {renderLink(
            useBrandedTranslation('lbl_app_privacy_notice_url'),
            handleAppPrivacyNoticesClick,
            useBrandedTranslation('lbl_app_privacy_notice'),
          )}
        </div>
      )
    }
  }
}
